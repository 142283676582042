import useSWR from "swr";

const fetcher = (key: string) => fetch(key).then((res) => res.json());

export const fetchAlertEventsById = async (eventId) => {
  const url =  `/api/alerts/events/alertEvent?eventId=${eventId}`;
  
  try {
    const response = await fetch(url);
    const alertEvent = await response.json();
    return alertEvent;
  } catch (e) {
      console.error("Invalid API response:", e);
  }
}

export const useAlertEventsById = (eventId) => {
  const { data, error, isLoading } = useSWR(
    `/api/alerts/events/alertEvent?eventId=${eventId}`,
    fetcher
  );

  return {
    data,
    error,
    isLoading,
  };
};
