import { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Flex,
  Stack,
  HStack,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanel,
  TabPanels,
  FormControl,
  FormLabel,
  Container,
  Spinner,
  Box,
  Divider,
  Tag,
} from "@chakra-ui/react";
import {
  fetchAlertEventsById,
  useAlertEventsById,
} from "@/services/useAlertEvents";
import { PostModalProvider } from "@/components/feeds/modal/post-modal-context";
import FeedPost from "@/components/feeds/feed-post";
import { Scroll } from "@/components/feeds/feed-layout-styled";
import { dateFormatter } from "@/common/utility/dateTimeUtil";
import { useCurrentUser } from "@/services/useCurrentUser";
import {
  LanguageProvider,
  useLanguage,
} from "@/components/userprofile/language/lang-context";
import useTheme from "@/common/utility/useTheme";
import { format, isValid } from "date-fns";
import { useRouter } from "next/router";

const dateFormat = "HH:mm:ss - MMM d, yyyy";
const AlertEventsModal = ({ isOpen, onClose, eventId }) => {
  const router = useRouter();
  const {
    state: { lang },
  } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [alertEvents, setAlertEvents] = useState<any>({});
  const { data: user } = useCurrentUser();
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const alertEvent = await fetchAlertEventsById(eventId);
      console.log("alertEvent", alertEvent);
      setAlertEvents(alertEvent);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const formatDate = (date) => {
    if (date) {
      return format(new Date(date), dateFormat);
    }
  };

  const handleEvent = (alertEvent) => {
    router.push(`globalfeed/view?id=${alertEvent.id}&isEvent=1`);
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {lang?.ALERTS_EVENTS_VIEW_TITLE || "Alert Event"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Flex ml={3}>
                <Box w={"33%"}>
                  <FormControl mt={2}>
                    <FormLabel>
                      {lang?.ALERTS_EVENTS_VIEW_NAME || "Alert Name"}
                    </FormLabel>
                    <Text padding={1} fontSize={"sm"}>
                      {alertEvents?.alertEvent?.alertName}
                    </Text>
                  </FormControl>
                </Box>
                <Box w={"33%"}>
                  <FormControl mt={2}>
                    <FormLabel>
                      {lang?.ALERTS_EVENTS_VIEW_TRIGGEREDAT || "Triggered at"}
                    </FormLabel>
                    <Text padding={1} fontSize={"sm"}>
                      {`${dateFormatter({
                        value: alertEvents?.alertEvent?.eventTriggeredDate,
                        formatType: "MMM dd, yyyy, h:mm:ss a",
                      })}`}
                    </Text>
                  </FormControl>
                </Box>
                <Box w={"33%"}>
                  <FormControl mt={2}>
                    <FormLabel>
                      {lang?.ALERTS_EVENTS_VIEW_TYPE || "Trigger Type"}
                    </FormLabel>
                    <Text padding={1} fontSize={"sm"}>
                      {" "}
                      {alertEvents?.alertEvent?.alertType}
                    </Text>
                  </FormControl>
                </Box>
              </Flex>

              <Box overflowY={"auto"} maxH={"500px"}>
                <Tabs>
                  <TabList>
                    <Tab>Posts</Tab>
                    <Tab>Recipients</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {alertEvents?.posts &&
                        alertEvents?.posts?.map((post, index) => (
                          <Box key={index}>
                            <Flex justifyContent={"space-between"}>
                              <Tag mb={2} bgColor={post.confidenceLevelColor}>
                                {post.confidenceLevel}
                              </Tag>
                            </Flex>
                            <Flex justifyContent={"space-between"}>
                              <Text mt={1} mb={1} fontSize={"lg"} fontWeight={"bold"}>
                                {post.title}
                              </Text>
                              <Text fontWeight="bold" ml={4} cursor={"pointer"}>
                                <i
                                  className="fa fa-ellipsis-h"
                                  aria-hidden="true"
                                  onClick={(e) => {
                                    handleEvent(post);
                                  }}
                                />
                              </Text>
                            </Flex>

                            <Divider />
                            <Flex justifyContent={"space-between"}>
                              <Text mt={1} mb={1}>
                                {post.locationDescription}
                              </Text>
                              <Text mt={1}>
                                {formatDate(post.indexedTimestamp)}
                              </Text>
                            </Flex>
                            <Text mt={10}>{post.content}</Text>
                          </Box>
                        ))}
                    </TabPanel>
                    <TabPanel>
                      <Box></Box>
                      <Flex flexDirection={"column"}>
                        <Flex
                          direction={"column"}
                          textAlign={"left"}
                          justifyContent={"flex-start"}
                          minWidth={"680px"}
                          flexBasis="100%"
                          maxHeight={"800px"}
                        >
                          <FormControl mt={2}>
                            <FormLabel>
                              {lang?.ALERTS_EVENTS_VIEW_RECIPIENTS_EMAILS ||
                                "Emails"}
                            </FormLabel>
                          </FormControl>
                          <Box maxW="500px">
                            {alertEvents?.alertEvent?.emails?.length > 0 ? (
                              alertEvents?.alertEvent?.emails?.map(
                                (email, index) => (
                                  <>
                                    <Text key={index} color={"#939597"}>
                                      {email}
                                    </Text>
                                  </>
                                )
                              )
                            ) : (
                              <>
                                <Text
                                  fontSize={"sm"}
                                  color={theme?.feed?.footer?.color}
                                >
                                  {lang?.NONE || "None"}
                                </Text>
                              </>
                            )}
                          </Box>
                          <FormControl mt={2}>
                            <FormLabel>
                              {lang?.ALERTS_EVENTS_VIEW_RECIPIENTS_SMS ||
                                "Sms Numbers"}
                            </FormLabel>
                          </FormControl>
                          <Box maxW="500px">
                            {alertEvents?.alertEvent?.smsNumbers?.length > 0 ? (
                              alertEvents?.alertEvent?.smsNumbers?.map(
                                (sms, index) => (
                                  <>
                                    <Text key={index} color={"#939597"}>
                                      {sms}
                                    </Text>
                                  </>
                                )
                              )
                            ) : (
                              <>
                                <Text fontSize={"sm"} color={"#939597"}>
                                  {lang?.NONE || "None"}
                                </Text>
                              </>
                            )}
                          </Box>
                          <FormControl mt={2}>
                            <FormLabel>
                              {lang?.ALERTS_EVENTS_VIEW_RECIPIENTS_TEAMS ||
                                "Teams"}
                            </FormLabel>
                          </FormControl>
                          <Box maxW="500px">
                            {alertEvents?.alertEvent?.teams?.length > 0 ? (
                              alertEvents?.alertEvent?.teams?.map(
                                (team, index) => (
                                  <>
                                    <Text key={index} color={"#939597"}>
                                      {team}
                                    </Text>
                                  </>
                                )
                              )
                            ) : (
                              <>
                                <Text fontSize={"sm"} color={"#939597"}>
                                  {lang?.NONE || "None"}
                                </Text>
                              </>
                            )}
                          </Box>
                        </Flex>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => onClose()}>
              {lang?.MESSAGE_CLOSE || "Close"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AlertEventsModal;
